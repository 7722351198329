import React, { ElementType, useMemo } from 'react';

import { MenuChevronUp, MenuChevronDown } from '@/assets';
import Tooltip from '@components/Tooltip';

import {
  MenuItemProps,
  MenuItemAsClickableProps,
  MenuItemAsLinkProps
} from './types';

import {
  RootItem,
  RootItemLink,
  RootItemText,
  RootItemIcon,
  RootItemToggle,
  SubMenuWrapper,
  SubMenuItem,
  SubMenuItemLink,
  Wrapper
} from './styles';

const MenuItemAsLink = ({
  $isOpen,
  $marginLeft,
  $padding,
  currentPath,
  icon,
  link,
  rootLink,
  submenu,
  title,
  tooltip,
}: MenuItemAsLinkProps) => {
  const Icon = icon as ElementType;

  const isRootActive = useMemo(() => {
    return currentPath.includes(rootLink);
  }, [currentPath, rootLink]);

  const childActiveIndex = useMemo(() => {
    if(!submenu){
      return -1;
    }

    return submenu.findIndex(submenuItem => {
      return currentPath.includes(submenuItem.link);
    });
  }, [currentPath, submenu]);

  const isRenderActiveState = (!submenu && isRootActive) || (!$isOpen && childActiveIndex >= 0);
  const isRenderSubmenuInActiveState = isRootActive && submenu && $isOpen;
  const isKeepSubmenuHidden = !$isOpen && submenu;

  const rootItemLinkComponent = 
    <RootItemLink
      $isActive={ isRenderActiveState }
      $padding={ $padding }
      to={ link }
    >
      {
        typeof icon === 'function' && icon()
      }
      {
        typeof icon !== 'function' &&
        <RootItemIcon>
          <Icon />
        </RootItemIcon>
      }
      {
        $isOpen &&
        <>
          <RootItemText $marginLeft={ $marginLeft }>
            {
              typeof title === 'function'
                ? title()
                : title
            }
          </RootItemText>
          {
            submenu &&
            <RootItemToggle>
              {
                isRootActive
                  ? <MenuChevronUp />
                  : <MenuChevronDown />
              }
            </RootItemToggle>
          }
        </>
      }
    </RootItemLink>
  ;

  return (
    <Wrapper
      $isOpen={ $isOpen }
      $withoutSubmenu={ !submenu }
    >
      { !$isOpen && !submenu ?
        <Tooltip
          placement={ 'right' }
          title={ tooltip ? tooltip : '' }
        >
          { rootItemLinkComponent }
        </Tooltip> :
        rootItemLinkComponent
      }
      {
        (isRenderSubmenuInActiveState || isKeepSubmenuHidden) &&
        <SubMenuWrapper $isOpen={ $isOpen }>
          {
            submenu.map(({ link, title }, index) => (
              <SubMenuItem key={ link }>
                <SubMenuItemLink
                  $isActive={ childActiveIndex === index }
                  $isOpen={ $isOpen }
                  to={ link }
                >
                  {title}
                </SubMenuItemLink>
              </SubMenuItem>
            ))
          }
        </SubMenuWrapper>
      }
    </Wrapper>
  );
};

const MenuItemAsClickable = ({
  $isOpen,
  $marginLeft,
  $padding,
  icon,
  onClick,
  title,
  tooltip
}: MenuItemAsClickableProps) => {
  const Icon = icon as ElementType;

  const rootItemIcon = 
    <RootItem
      $isActive={ false }
      $padding={ $padding }
      onClick={ onClick }
    >
      {
        typeof icon === 'function' && icon()
      }
      {
        typeof icon !== 'function' &&
        <RootItemIcon>
          <Icon />
        </RootItemIcon>
      }
      {
        $isOpen &&
        <RootItemText $marginLeft={ $marginLeft }>
          {
            typeof title === 'function'
              ? title()
              : title
          }
        </RootItemText>
      }
    </RootItem>
  ;

  return (
    <Wrapper 
      $isOpen={ $isOpen }
      $withoutSubmenu={ true }
    >
      { !$isOpen ?
        <Tooltip
          placement={ 'right' }
          title={ tooltip ? tooltip : '' }
        >
          { rootItemIcon }
        </Tooltip> :
        rootItemIcon
      }
    </Wrapper>
  );
};


export const MenuItem = (props: MenuItemProps) => {
  const { asLink } = props;
  
  if(asLink) {
    return (
      <MenuItemAsLink
        $isOpen={ props.$isOpen }
        $marginLeft={ props.$marginLeft }
        $padding={ props.$padding }
        currentPath={ props.currentPath }
        icon={ props.icon }
        link={ props.link }
        rootLink={ props.rootLink }
        submenu={ props?.submenu }
        title={ props.title }
        tooltip={ props.tooltip }
      />
    );
  }

  if(!asLink){
    return (
      <MenuItemAsClickable
        $isOpen={ props.$isOpen }
        $marginLeft={ props.$marginLeft }
        $padding={ props.$padding }
        icon={ props.icon }
        onClick={ props.onClick }
        title={ props.title }
        tooltip={ props.tooltip }
      />
    );
  }

  return null;
};
