import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useWatch, useFormContext } from 'react-hook-form';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';

import { EMAIL_OPTIONS, LEFT_COLUMN_WIDTH, PHONE_OPTIONS } from '@/modules/AddContactModal/data';
import { PRIMARY_POINT_FIELDS_NAME } from '@/modules/AddContactModal/components/PrimaryPoint/data';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { AddContactProps } from './types';


export const AddContact = observer(({
  setNewContact
}: AddContactProps) => {

  const { control, clearErrors, setValue } = useFormContext();

  const [ firstName, lastName, phoneNumber, email ] = useWatch({
    control,
    name: [
      PRIMARY_POINT_FIELDS_NAME.firstName,
      PRIMARY_POINT_FIELDS_NAME.lastName,
      PRIMARY_POINT_FIELDS_NAME.phoneNumber,
      PRIMARY_POINT_FIELDS_NAME.email
    ]
  });

  const store = useAddContactPopupStore();
  const isLinkedContact = Boolean(store.linkedContact);

  useEffect(() => {
    const fields = [firstName, lastName, phoneNumber, email];

    const isDirtyFields = fields.some(Boolean);
    // TODO by default the phone number must be set NULL if only the country code is entered
    const isOnlyCountryCode = getIsOnlyCountryCode(phoneNumber) && !firstName && !lastName && !email;

    setNewContact(isDirtyFields && !isOnlyCountryCode);
    setValue(PRIMARY_POINT_FIELDS_NAME.newContact, isDirtyFields && !isOnlyCountryCode);
  
    if(!isEmpty(fields) && isOnlyCountryCode) {
      clearErrors([
        PRIMARY_POINT_FIELDS_NAME.firstName,
        PRIMARY_POINT_FIELDS_NAME.lastName,
        PRIMARY_POINT_FIELDS_NAME.phoneNumber,
        PRIMARY_POINT_FIELDS_NAME.email
      ]);
    }
  }, [clearErrors, firstName, lastName, phoneNumber, email]);

  return (
    <>
      <FormBlock.RowWrapper>
        <HFHiddenInput
          control={ control }
          defaultValue={ false }
          name={ PRIMARY_POINT_FIELDS_NAME.newContact }
        />

        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 15
            }
          } }
          isWrap={ false }
          label={ 'Add Contact' }
        >
          <HFTextInput
            control={ control }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.firstName }
            placeholder={ 'Enter First Name' }
          />
          <HFTextInput
            control={ control }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.lastName }
            placeholder={ 'Enter Last Name' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 15,
            }
          } }
          isWrap={ false }
          label={ 'Phone' }
        >
          <HFPhoneField
            control={ control }
            countryListWidth={ 320 }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.phoneNumber }
          />
          <HFSelect
            control={ control }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.phoneType }
            options={ PHONE_OPTIONS }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH }
          childrenWrapperProps={ {
            style: {
              gap: 10,
            }
          } }
          isWrap={ false }
          label={ 'Email' }
        >
          <HFTextInput
            control={ control }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.email }
            placeholder={ 'Enter Email Address' }
          />
          <HFSelect
            control={ control }
            disabled={ isLinkedContact }
            name={ PRIMARY_POINT_FIELDS_NAME.emailType }
            options={ EMAIL_OPTIONS }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </>
  );
});